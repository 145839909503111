module.exports = {
  siteTitle: 'peaonunes',
  siteTitleAlt: 'Rafael Nunes personal website.',
  siteTitleShort: 'peaonunes',
  siteUrl: process.env.ROOT_URL || 'https://peaonunes.com',
  lang: 'en',
  pathPrefix: '/',
  siteLogo: 'images/logo.png',
  siteDescription:
    "I'm Rafael Nunes, full time software engineer and learner. Working on empowering people to realise the full value of their skills at Airtasker. Trying to share knowledge being a casual blog post writer & open sourcerer.",
  minibio: `
    I'm Rafael Nunes, full time software engineer and learner.
    Working on empowering people to realise the full value of their skills at Airtasker.
    Trying to share knowledge being a casual blog post writer & open sourcerer.
  `,
  author: 'Rafael Nunes',
  organization: 'Rafael Nunes',
  username: 'peaonunes',
  twitterHandle: '@peaonunes',
  twitter: 'https://twitter.com/peaonunes/',
  github: 'https://github.com/peaonunes/',
  linkedin: 'https://www.linkedin.com/in/rngds/',
  medium: 'https://medium.com/@peaonunes',
  devto: 'https://dev.to/peaonunes',
};
