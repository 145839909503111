import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Footer } from './Footer';
import Header from './Header';
import SEO from '../components/Seo';

import '../styles/tailwind.css';
import '../styles/index.css';

const useHeader = () => {
  useEffect(() => {
    window.onscroll = function () { updatedHeaderStyle() };

    let header = document.getElementsByTagName('header')[0];
    let main = document.getElementsByTagName('main')[0];

    let mainOffset = main.offsetTop;

    function updatedHeaderStyle() {
      if (window.pageYOffset >= mainOffset) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  }, []);
}

const Layout = ({ children }) => {
  useHeader();

  return (
    <React.Fragment>
      <Header className="gradient" />
      <div className="max-w-4xl m-auto">
        <main className="p-2 min-h-screen">{children}</main>
      </div>
      <Footer />
    </React.Fragment>
  );
};

const HomeLayout = ({ children }) => {
  useHeader();

  return (
    <React.Fragment>
      <SEO title="Home" />
      <div style={{ zIndex: 2 }} className="relative">
        <Header home />
      </div>
      {children}
      <Footer />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
export { HomeLayout };
