import React from 'react';

import { LinkedIn, Twitter, Medium, DevTo, GitHub, RSS } from './Icons';
import config from '../../config';

const SocialMedia = () => (
  <div className="flex">
    <a
      className="px-2"
      target="_blank"
      rel="noopener noreferrer"
      href={config.twitter}
    >
      <Twitter />
    </a>
    <a
      className="px-2"
      target="_blank"
      rel="noopener noreferrer"
      href={`${config.siteUrl}/blog/rss.xml`}
    >
      <RSS />
    </a>
    <a
      className="px-2"
      target="_blank"
      rel="noopener noreferrer"
      href={config.github}
    >
      <GitHub />
    </a>
    <a
      className="px-2"
      target="_blank"
      rel="noopener noreferrer"
      href={config.medium}
    >
      <Medium />
    </a>
    <a
      className="px-2"
      target="_blank"
      rel="noopener noreferrer"
      href={config.devto}
    >
      <DevTo />
    </a>
    <a
      className="px-2"
      target="_blank"
      rel="noopener noreferrer"
      href={config.linkedin}
    >
      <LinkedIn />
    </a>
  </div>
);

const Footer = () => (
  <footer className="min-w-full p-4 text-sm bg-gray-100">
    <div className="flex flex-col items-center sm:flex sm:flex-row sm:justify-center">
      <p className="pb-2 sm:p-0">
        peaonunes © {new Date().getFullYear()}, now built with {` `}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.gatsbyjs.org"
        >
          Gatsby
        </a>
      </p>
      <SocialMedia />
    </div>
  </footer>
);

export { Footer };
