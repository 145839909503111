import { useStaticQuery, graphql } from 'gatsby';
import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import { Twitter, RSS, GitHub } from './Icons';
import config from '../../config';

const AppLink = ({ to, className = '', children }) => {
  const classNames = classnames('appearance-none hover:underline', className);
  return (
    <Link to={to} className={classNames}>
      {children}
    </Link>
  );
};

const SocialLink = ({ children, href, className }) => {
  const classNames = classnames('hover:underline block py-1 sm:px-2 sm:py-0', className);

  return (
    <a
      href={href}
      className={classNames}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

const Menu = ({ isOpen }) => {
  const mobileMenuClassName = classnames(
    'menu px-2 sm:hidden',
    { 'fadeIn py-4 flex flex-col items-start z-10 absolute w-full h-screen': isOpen },
    { 'fadeOut': !isOpen }
  )

  return (
    <React.Fragment>
      <div className={mobileMenuClassName}>
        <AppLink to="/blog" className="block py-2">
          <strong>→ blog</strong>
        </AppLink>
        <AppLink to="/drops" className="block py-2">
          <strong>→ drops</strong>
        </AppLink>
        <SocialLink href={config.twitter} className="py-2">
          <strong>→ twitter</strong>
        </SocialLink>
        <SocialLink href={`${config.siteUrl}/blog/rss.xml`} className="py-2">
          <strong>→ RSS</strong>
        </SocialLink>
        <SocialLink href={config.github} className="py-2">
          <strong>→ github</strong>
        </SocialLink>
        <SocialLink href={config.medium} className="py-2">
          <strong>→ medium</strong>
        </SocialLink>
        <SocialLink href={config.devto} className="py-2">
          <strong>→ devto</strong>
        </SocialLink>
        <SocialLink href={config.linkedin} className="py-2">
          <strong>→ linkedin</strong>
        </SocialLink>
      </div>
      <div
        className={'hidden pl-2 bg-white sm:flex sm:flex-row sm:bg-transparent'}
      >
        <SocialLink href={config.twitter}>
          <Twitter hover="hover:text-white" />
        </SocialLink>
        <SocialLink href={`${config.siteUrl}/blog/rss.xml`}>
          <RSS hover="hover:text-white" />
        </SocialLink>
        <SocialLink href={config.github}>
          <GitHub hover="hover:text-white" />
        </SocialLink>
      </div>
    </React.Fragment>
  )
}

const Header = ({ home = false }) => {
  const [isOpen, setOpen] = useState(false);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const headerClassName = classnames({
    'regular': home,
    'with-background': !home,
  })
  const headerWrapper = classnames(
    'px-2 pb-4 sm:px-0 flex justify-between items-center'
  )

  return (
    <header className={headerClassName}>
      <div className="pt-4 sm:flex sm:justify-between sm:m-auto sm:max-w-4xl sm:px-0">
        <div className={headerWrapper}>
          <div className="flex sm:w-64">
            <h1 style={{ margin: 0 }} className="font-semibold sm:px-2">
              <AppLink to="/">{data.site.siteMetadata.title}</AppLink>
            </h1>
            <div className="hidden sm:px-2 sm:flex sm:w-auto sm:justify-between">
              <AppLink className="pr-4" to="/blog">
                blog
              </AppLink>
              <AppLink className="pr-4" to="/drops">
                drops
              </AppLink>
            </div>
          </div>
          <div className="sm:hidden">
            <button
              type="button"
              className="block focus:outline-none"
              onClick={() => setOpen(!isOpen)}
            >
              {!isOpen && (
                <span
                  className="hover:shadow-sm rounded-md"
                  role="img"
                  aria-label="open menu"
                >
                  🔗
                </span>
              )}
              {!!isOpen && (
                <span
                  className="hover:shadow-sm rounded-md"
                  role="img"
                  aria-label="close menu"
                >
                  ❌
                </span>
              )}
            </button>
          </div>
        </div>
        <Menu isOpen={isOpen} />
      </div>
    </header>
  );
};

export default Header;
